import { render, staticRenderFns } from "./UnitStar.vue?vue&type=template&id=5d56f757&"
import script from "./UnitStar.vue?vue&type=script&lang=js&"
export * from "./UnitStar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d56f757')) {
      api.createRecord('5d56f757', component.options)
    } else {
      api.reload('5d56f757', component.options)
    }
    module.hot.accept("./UnitStar.vue?vue&type=template&id=5d56f757&", function () {
      api.rerender('5d56f757', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8mjsuu5derP8X/JS/UnitStar.vue"
export default component.exports