var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "stars", attrs: { title: _vm.title } },
    [
      _vm._l(this.stars, function(n, index) {
        return _c("i", { staticClass: "fa fa-star" })
      }),
      _vm._v(" "),
      _vm._l(5 - this.stars, function(n, index) {
        return _c("i", { staticClass: "far fa-star" })
      }),
      _vm._v(" "),
      _c("span", [_vm._v(" " + _vm._s(_vm.text) + " ")])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }