<template>
    <span class="stars"
        :title="title">
      <i v-for="(n, index) in  this.stars"
          class="fa fa-star"></i>
      <i v-for="(n, index) in 5 - this.stars"
          class="far fa-star"></i>
      <span> {{text}} </span>
    </span>
</template>

<script>
    import V from 'voUtils/V.js'


    export default {
    	voVueComponent: 'star-rating',
    	props: {
    		stars: Number,
    	},
    	computed: {
    		title: function() {
    			return 'Klassifizierung: ' + this.stars + ' Sterne';
    		},
    		text: function() {
    			return "(" + this.stars + "/5)";
    		}
    	},
    	mounted: function() {

    	},
    	methods: {

    	}


    };
</script>